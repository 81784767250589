import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import {
  Divider,
  Grid,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GetHeaderText } from "./DatagridFunctions";
import { CustomChip } from "./individualView";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import { RefetchContext } from "./refetchProvider";
import { useMutation } from "@apollo/client";
import useUpsertTodo from "./useUpsertTodo";
import { useNavigate } from "react-router-dom";

const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

function getDueDateColor(dueDate: string) {
  const now = new Date();
  const due = new Date(dueDate);
  const diff = due.getTime() - now.getTime();
  const diffDays = diff / (1000 * 3600 * 24);

  if (diffDays < 0) {
    return "#F8C9D2";
  } else if (diffDays < 1) {
    return "#FFDAC2";
  } else {
    return "#38C793";
  }
}

function getDueDateTextColor(dueDate: string) {
  const now = new Date();
  const due = new Date(dueDate);
  const diff = due.getTime() - now.getTime();
  const diffDays = diff / (1000 * 3600 * 24);

  if (diffDays < 0) {
    return "#710E21";
  } else if (diffDays < 1) {
    return "#6E330C";
  } else {
    return "#227656";
  }
}

export default function InboxCheckList(props: {
  todos: Array<any>;
  setSelectedRow?: (selectedRow: string) => void;
  selectedRow?: string;
  setViewId: any;
}) {
  const { todos, setSelectedRow, selectedRow, setViewId } = props;
  const [checked, setChecked] = React.useState<string[]>([]);
  const context = React.useContext(RefetchContext);
  const { updateTodo } = useUpsertTodo();

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  React.useEffect(() => {
    const completedTodoIds = todos
      .filter((todo) => todo.status === 2)
      .map((todo) => todo._id);
    setChecked(completedTodoIds);
  }, [todos]);

  const completeTodo = async (todo: any) => {
    // Optimistically update the UI immediately
    setChecked((prev) => [...prev, todo._id]);

    // Update in the background - no await to keep UI responsive
    updateTodo({
      variables: {
        query: {
          _id: todo._id,
        },
        set: {
          status: 2,
          triggeredBy: "client",
        },
      },
    }).catch((error) => {
      // If there's an error, revert the optimistic update
      console.error("Error completing todo:", error);
      setChecked((prev) => prev.filter((id) => id !== todo._id));
    });
  };

  const handleToggle = (todoId: string) => () => {
    const todo = todos.find((t) => t._id === todoId);
    if (!todo) return;

    // Check if already marked as completed
    if (todo.status === 2 || checked.includes(todoId)) return;

    completeTodo(todo);
  };

  React.useEffect(() => {
    if (!!!selectedRow) {
      const tempSelected = "";
      todos
        ?.sort((a: any, b: any) => {
          return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
        })
        ?.filter((todo) => {
          return todo.status === 1;
        })
        .forEach((todo) => {
          if (!!todo?.rowId && !!!tempSelected) {
            setSelectedRow && setSelectedRow(todo?.rowId?._id || "");
            setViewId && setViewId(todo?.rowId?.viewId?._id || "");
          }
        });
    }
  }, [todos, selectedRow]);

  const filteredTodos = todos?.sort((a: any, b: any) => {
    // Extract and normalize the due dates to ignore time
    const dueDateA = new Date(a.dueDate);
    dueDateA.setHours(0, 0, 0, 0);
    const dueDateB = new Date(b.dueDate);
    dueDateB.setHours(0, 0, 0, 0);

    // Compare by due date
    if (dueDateA.getTime() !== dueDateB.getTime()) {
      return dueDateA.getTime() - dueDateB.getTime();
    }

    // If due dates are the same, compare by created date
    const createdDateA = new Date(a.createdAt).getTime();
    const createdDateB = new Date(b.createdAt).getTime();

    return createdDateB - createdDateA;
  });
  // ?.filter((todo) => {
  //   return todo.status === 1;
  // });

  if (!!!filteredTodos || filteredTodos?.length === 0) {
    return (
      <div style={{ padding: "10px" }}>
        <Typography variant="h6" style={{ textAlign: "center", color: "gray" }}>
          All Done!
        </Typography>
      </div>
    );
  }

  return (
    <List
      key={"InboxCheckList"}
      dense
      sx={{
        width: "100%",
        // maxWidth: 500,
        bgcolor: "background.paper",
        padding: "0px",
      }}
    >
      {filteredTodos?.map((todo, index) => {
        const labelId = `checkbox-list-secondary-label-${todo._id}`;

        const rowObj = JSON.parse(todo?.rowId?.rowObject || "{}");

        return (
          <div key={`todo-${index}`}>
            <ListItem
              key={`${todo._id}-${index}`}
              disablePadding
              style={{ padding: "0px 0px" }}
            >
              <ListItemButton
                onClick={(e) => {
                  // Make sure we're not clicking the checkbox area
                  const target = e.target as HTMLElement;
                  if (target.closest(".MuiCheckbox-root")) {
                    return;
                  }

                  if (isMobile) {
                    navigate(`/individualView/${todo?.rowId?._id}`);
                  } else {
                    !!setSelectedRow &&
                      setSelectedRow(todo?.rowId?._id || "no-row");

                    setViewId && setViewId(todo?.rowId?.viewId?._id || "");
                  }
                }}
                selected={todo?.rowId?._id === selectedRow}
                sx={{
                  padding: "15px 0px",
                  alignItems: "start",
                  opacity:
                    todo.status === 2 || checked.includes(todo._id) ? 0.7 : 1,
                  backgroundColor:
                    todo.status === 2 || checked.includes(todo._id)
                      ? "rgba(56, 199, 147, 0.05)"
                      : "inherit",
                  transition: "all 0.15s ease-out",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    onChange={(e) => {
                      // Prevent the event from bubbling up to the ListItemButton
                      e.stopPropagation();
                      handleToggle(todo._id)();
                    }}
                    checked={todo.status === 2 || checked.includes(todo._id)}
                    size="small"
                    inputProps={{ "aria-labelledby": labelId }}
                    sx={{
                      paddingTop: "2px",
                      transition: "color 0.15s ease-out",
                      "&.Mui-checked": {
                        color: "#38C793",
                      },
                    }}
                  />
                </ListItemIcon>
                <div style={{ width: "100%" }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "4px",
                          fontWeight: "500",
                          color: "#344054",
                        }}
                      >
                        {GetHeaderText(rowObj, todo?.rowId?.viewId?.columns)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          marginBottom: "10px",
                          textDecoration:
                            todo.status === 2 || checked.includes(todo._id)
                              ? "line-through"
                              : "none",
                          color:
                            todo.status === 2 || checked.includes(todo._id)
                              ? "#6E7276"
                              : "inherit",
                          transition: "all 0.15s ease-out",
                        }}
                      >
                        {todo.text}
                      </Typography>
                      {!!context?.workspaceMembers &&
                      context?.workspaceMembers?.length > 1 ? (
                        <CustomChip
                          label={todo.assignedTo?.name}
                          size="small"
                          sx={{
                            marginRight: "5px",
                            fontSize: "12px",
                          }}
                          avatar={
                            <PersonIcon
                              sx={{
                                fontSize: "10px",
                                color: "#525866",
                                marginRight: "5px !important",
                              }}
                            />
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <CustomChip
                        label={`${new Date(todo.dueDate).toLocaleDateString()}`}
                        // color={getDueDateColor(todo.dueDate)}
                        size="small"
                        sx={{
                          backgroundColor: getDueDateColor(todo.dueDate),
                          color: getDueDateTextColor(todo.dueDate),
                          fontWeight: "500",
                          fontSize: "12px",
                          float: "right",
                          marginRight: "10px",
                        }}
                        avatar={
                          <TodayIcon
                            sx={{
                              fontSize: "10px",
                              color: `${getDueDateTextColor(
                                todo.dueDate
                              )} !important`,
                              height: "15px !important",
                              marginRight: "5px !important",
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </ListItemButton>
            </ListItem>
            <Divider component="li" />
          </div>
        );
      })}
    </List>
  );
}
