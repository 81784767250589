import { useEffect, useContext } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  GET_TODOS,
  CREATE_TODO_MUTATION,
  UPDATE_ONE_TODO,
} from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { gql } from "@apollo/client";

const useUpsertTodo = () => {
  const { socket } = useContext(SocketIOContext) as any;
  const client = useApolloClient();
  const { user } = useAuth0();

  const userQuery = { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const updateTodoCache = (cache: any, upsertedTodo: any) => {
    const workspaceId = _.get(upsertedTodo, "workspaceId");
    const rowId = _.get(upsertedTodo, "rowId._id");

    if (!_.isNil(upsertedTodo)) {
      const upsertedTodoId = _.chain(upsertedTodo).get("_id").value();
      const isUpdatedTodoDeleted = _.chain(upsertedTodo)
        .get("isDeleted", false)
        .value();
      const todoStatus = _.chain(upsertedTodo).get("status").value();

      const updateCache = (payload: any) => {
        const { query, newTodo } = payload;

        const newTodoId = _.chain(newTodo).get("_id").value();

        // Read the current cache for the GET_TODOS query
        try {
          const existingTodos = cache.readQuery({
            query: GET_TODOS,
            variables: { query },
          });

          let upsertedTodos = [newTodo];

          // @ts-ignore
          if (existingTodos && existingTodos.todos) {
            // Add the new todo to the cached list
            // Check if this todo already exists in cache
            const existingTodosList = _.get(existingTodos, "todos", []);
            const isUpdatedTodo = existingTodosList.some(
              (todo: any) => _.get(todo, "_id") === newTodoId
            );

            upsertedTodos = existingTodosList;

            if (isUpdatedTodo) {
              if (isUpdatedTodoDeleted) {
                // @ts-ignore
                upsertedTodos = existingTodos.todos.filter(
                  (todo: any) => _.get(todo, "_id") !== newTodoId
                );
              } else {
                // Update the existing todo in cache
                // @ts-ignore
                upsertedTodos = existingTodos.todos.map((todo: any) => {
                  return _.get(todo, "_id") === newTodoId ? newTodo : todo;
                });
              }
            } else {
              // @ts-ignore
              upsertedTodos = [...upsertedTodos, newTodo];
            }
          }

          // Write the updated list back to cache
          cache.writeQuery({
            query: GET_TODOS,
            variables: { query },
            data: {
              todos: upsertedTodos,
            },
          });
        } catch (error) {
          console.warn("Error updating cache:", error);
          // Force a refetch to ensure data is consistent
          client.refetchQueries({
            include: [GET_TODOS],
          });
        }
      };

      updateCache({
        query: { workspaceId, isDeleted: false },
        newTodo: upsertedTodo,
      });
      updateCache({ query: userQuery, newTodo: upsertedTodo });

      if (!_.isNil(rowId)) {
        updateCache({
          query: { rowId: { _id: rowId }, isDeleted: false },
          newTodo: upsertedTodo,
        });
      }
    }
  };

  const [updateTodo, updateTodoProps] = useMutation(UPDATE_ONE_TODO, {
    optimisticResponse: (variables: any) => {
      // For todo completion (status=2), create a simple optimistic response
      if (variables?.set?.status === 2) {
        return {
          updateOneTodo: {
            __typename: "Todo",
            _id: variables.query._id,
            status: 2,
            // Other fields can be undefined for optimistic UI
            priority: undefined,
            text: undefined,
            userId: undefined,
            rowId: undefined,
            dueDate: undefined,
            createdAt: undefined,
            isDeleted: false,
            assignedTo: undefined,
          },
        };
      }
      return undefined; // For non-completion updates, don't use optimistic response
    },
    update(cache, { data }) {
      const updatedTodoId = data?.updateOneTodo?._id;

      updateTodoCache(cache, data?.updateOneTodo);

      socket.emit("update-todo", updatedTodoId);
    },
  });

  const [createTodo, createTodoProps] = useMutation(CREATE_TODO_MUTATION, {
    update(cache, { data }) {
      const updatedTodoId = data?.insertOneTodo?._id;

      updateTodoCache(cache, data?.insertOneTodo);

      socket.emit("update-todo", updatedTodoId);
    },
  });

  return {
    updateTodoCache,
    updateTodo,
    updateTodoProps,
    createTodo,
    createTodoProps,
  };
};

export default useUpsertTodo;
